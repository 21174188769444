/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createContext } from "react";

/**
 * Interface de uso comum User
 */
export interface User {
  apelido: string;
}

/**
 * Interface do user já autenticado
 */
export interface UserAuth {
  authenticated: boolean;
  user?: User | null;
  /**
   * Function que tentar autenticar usuario, caso consiga seta valores no localStore e headers do axios,
   * redirecionando a tela de home da aplicação
   */
  login(email: string, password: string): Promise<void>;
  /**
   * Faz a limpeza dos dados do usuario quando é solicitado o logout
   */
  logout(): void;
  /**
   *  vindo do setStatus, faz a validação de loadings
   */
  loading: boolean;
}

/**
 * Contexto da autenticação do usuario, valor padrão é passado, mas é substituido durante a rota.
 */
export const AuthContext = createContext<UserAuth>({
  authenticated: false,
  login: async (_: string, __: string) => {},
  logout: () => {},
  loading: true,
});
