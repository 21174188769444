import { ChecagemError } from "@/clients/Api";
import { IStatus, recuperaStatus } from "@/clients/Status";
import { swalConfig } from "@/css/Padroes";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

/**
 * @description
 * @param {number} pendente - quantidade de pendentes
 * @param {number} listado - quantidade de listados
 * @param {number} iniciado - quantidade de iniciados
 * @param {number} falha - quantidade de falhas
 * @param {number} sucesso - quantidade de sucessos
 */
interface Progress {
  pendente: number;
  listado: number;
  iniciado: number;
  falha: number;
  sucesso: number;
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const LoadingBarsHome = () => {
  const [status, setStatus] = useState<IStatus[]>([]);
  // const [calendario, setCalendario] = useState<ICalendario[]>([]);

  const [atualiza, setAtualiza] = useState(false);

  useEffect(() => {
    recuperaStatus()
      .then((res) => {
        setStatus(res.status);
      })
      .catch((err) => {
        const error = ChecagemError(err);

        Swal.fire({
          ...swalConfig,
          icon: "error",
          title: "Oops...",
          text: error.error.toString(),
          showConfirmButton: false,
          timer: 1500,
        });
      });

    delay(15000).then(() => setAtualiza(!atualiza));
  }, [atualiza]);

  const handleLoadingBar = (Progress: Progress, id: string) => {
    const { pendente, listado, iniciado, falha, sucesso } = Progress;
    const total = pendente + listado + iniciado + falha + sucesso;

    const pendenteP = (pendente / total) * 100;
    const listadoP = (listado / total) * 100;
    const iniciadoP = (iniciado / total) * 100;
    const falhaP = (falha / total) * 100;
    const sucessoP = (sucesso / total) * 100;

    const pendentePerc = pendenteP;
    const listadoPerc = listadoP;
    const iniciadoPerc = iniciadoP;
    const falhaPerc = falhaP;
    const sucessoPerc = sucessoP;

    /**
     * @description mostra parte da barra de loading
     * @param perc percentual da barra
     * @param name nome da class
     * @param color cor da barra
     * @returns retorna a barra de loading
     */
    const showLoadingSection = (
      perc: number,
      name: string,
      color: string,
      floater: boolean
    ) => {
      /**
       * @description pega a posição do mouse e seta a posição do título
       */
      const getMousePos = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!e.target) return;

        const target = e.target as HTMLElement;

        const rect = target.getBoundingClientRect();

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const title = target.lastChild as HTMLElement;
        title.style.left = x + 10 + "px";
        title.style.top = y - 5 + "px";
      };

      /**
       * @description mostra/esconde o título da barra
       */
      const handleMouseInteraction = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
      ) => {
        const target = e.target as HTMLElement;

        const title = target.lastChild as HTMLElement;

        if (title.style.opacity === "1") {
          title.style.opacity = "0";
          return;
        } else {
          title.style.opacity = "1";
          return;
        }
      };

      return (
        <div
          data-id={id}
          className={"loading " + name}
          style={{ width: perc + "%", backgroundColor: color }}
          {...(!floater
            ? {}
            : {
                onMouseEnter: (e) => {
                  handleMouseInteraction(e);
                },
                onMouseMove: (e) => {
                  getMousePos(e);
                },
                onMouseLeave: (e) => {
                  handleMouseInteraction(e);
                },
              })}
        >
          <p>{floater ? <></> : Math.round(perc) + "%"}</p>

          {floater ? (
            <div
              className="title"
              style={{
                opacity: 0,
                pointerEvents: "none",
              }}
            >
              {Math.round(perc) + "%"}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    };

    return (
      <div className="loading-bar">
        {showLoadingSection(
          pendentePerc,
          "pendente",
          "yellow",
          pendentePerc < 5 && pendentePerc > 0
        )}
        {showLoadingSection(
          listadoPerc,
          "listado",
          "#1EDFAA",
          listadoPerc < 5 && listadoPerc > 0
        )}
        {showLoadingSection(
          iniciadoPerc,
          "iniciado",
          "#5F1EDF",
          iniciadoPerc < 5 && iniciadoPerc > 0
        )}
        {showLoadingSection(
          falhaPerc,
          "falha",
          "#E92626",
          falhaPerc < 5 && falhaPerc > 0
        )}
        {showLoadingSection(
          sucessoPerc,
          "sucesso",
          "#4CDF1E",
          sucessoPerc < 5 && sucessoPerc > 0
        )}
      </div>
    );
  };
  const ShowLoadingBars = status.map(
    ({ nome, total, resultado, mesAno }, index) => {
      return (
        <div className="loading-bar-container" key={index}>
          <div className="loading-bar-title" style={{ color: "black" }}>
            RPA {nome} - {mesAno}
          </div>
          {handleLoadingBar(
            {
              pendente: resultado.PENDENTE / total,
              listado: resultado.LISTADO / total,
              iniciado: resultado.INICIADO / total,
              falha: resultado.FALHA / total,
              sucesso: resultado.SUCESSO / total,
            },
            `${index.toString()}-loading-${mesAno}${nome}`
          )}
        </div>
      );
    }
  );

  return <>{ShowLoadingBars}</>;
};

export default LoadingBarsHome;
