import { Outlet, useLocation } from "react-router-dom";
import { icons } from "@/assets/icons";
import { AuthContext } from "@/Contexts/Auth";
import { useContext, useEffect, useState } from "react";
import TopBar from "./TopBar";
import { Link } from "react-router-dom";

const SideBar: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const [fullSCreen, setFullScreen] = useState(false);
  const [showSideBar, setShowSideBar] = useState(window.innerWidth > 769);

  // click do botão de logout que chama a functiona logout
  const handlelogout = () => {
    logout();
  };

  const {
    Bell,
    Calendar3,
    Gear,
    HouseDoorFill,
    JournalBookmark,
    PersonCircle,
    ArrowBarRight,
    CaretRightFill,
  } = icons;

  const scrollStyling = () => {
    const pageBody = document.querySelector(".page-body") as HTMLElement;
    if (!pageBody) return;

    const scrollValue = pageBody.scrollTop;
    const maxScroll = pageBody.scrollHeight - pageBody.clientHeight;

    switch (scrollValue) {
      case 0:
        document.body.style.setProperty("--top", "0");
        break;
      case maxScroll:
        document.body.style.setProperty("--bottom", "0");
        break;
      default:
        document.body.style.setProperty("--top", "10px");
        document.body.style.setProperty("--bottom", "10px");
        break;
    }
  };

  const handleScrollBarFirulagem = () => {
    window.addEventListener(
      "scroll",
      () => {
        scrollStyling();
      },
      true
    );
  };

  const isWindowMobile = () => {
    const topBar = document.querySelector(".top-bar") as HTMLElement;
    const fullScreenBTN = document.querySelector(
      ".fullScreenBTN"
    ) as HTMLElement;
    if (!topBar || !fullScreenBTN) return;
    if (window.innerWidth < 769) {
      setFullScreen(true);
      topBar.style.display = "none";
      topBar.classList.add("hidden");
      fullScreenBTN.style.display = "none";
    } else {
      setFullScreen(false);
      topBar.style.display = "flex";
      topBar.classList.remove("hidden");
      fullScreenBTN.style.display = "flex";
    }
  };

  useEffect(() => {
    handleScrollBarFirulagem();

    window.addEventListener(
      "resize",
      () => {
        isWindowMobile();
      },
      true
    );

    return () => {
      window.removeEventListener(
        "scroll",
        () => {
          scrollStyling();
        },
        true
      );
      window.removeEventListener("resize", () => {
        isWindowMobile();
      });
    };
  }, []);

  const Location = useLocation();
  useEffect(() => {
    const allRedirects = document.querySelectorAll(".sideRedirect");
    const currPath = window.location.href.split("/")[3];

    allRedirects.forEach((item) => {
      // item.addEventListener("click", () => {
      //   item.classList.add("active");
      // });

      const itemPath = item.getAttribute("data-path")?.replace("/", "");

      if (itemPath === currPath) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }, [Location]);

  useEffect(() => {
    const fullScreen = document.querySelector(
      ".fullScreenBTN"
    ) as HTMLButtonElement;
    const sideBar = document.querySelector(".side-bar");
    const topBar = document.querySelector(".top-bar");

    if (!showSideBar) {
      sideBar?.classList.add("hidden");
      setShowSideBar(true);
      return;
    }

    if (!fullScreen || !sideBar || !topBar) {
      return;
    }

    if (fullSCreen) {
      sideBar.classList.add("hidden");
      sideBar.classList.add("fullScreen");
      topBar.classList.add("fullScreen");
    } else {
      sideBar.classList.remove("hidden");
      sideBar.classList.remove("fullScreen");
      topBar.classList.remove("fullScreen");
    }
  }, [fullSCreen]);

  const hideSideBar = () => {
    const sideBar = document.querySelector(".side-bar");
    const topBar = document.querySelector(".top-bar");

    if (!sideBar) return;

    sideBar.classList.toggle("hidden");

    if (topBar?.classList.contains("hidden")) return;

    if (sideBar.classList.contains("fullScreen")) {
      setFullScreen(false);
    }
  };

  return (
    <>
      <TopBar />
      <div className="app-body">
        <div
          className={
            "side-bar " /*+ (window.innerWidth < 769 ? "hidden" : "")*/
          }
        >
          <div className="itensSideBar">
            <ul>
              <Link to="/">
                <li className="sideRedirect" data-path="/">
                  {HouseDoorFill}
                  <p>DashBoard</p>
                  <div className="floater" />
                </li>
              </Link>
              <Link to="/participante">
                <li className="sideRedirect" data-path="/participante">
                  {PersonCircle}
                  <p>Participantes</p>
                  <div className="floater" />
                </li>
              </Link>
              <Link to="/processo">
                <li className="sideRedirect" data-path="/processo">
                  {JournalBookmark}
                  <p>Processos</p>
                  <div className="floater" />
                </li>
              </Link>

              <Link to="/tarefa">
                <li className="sideRedirect" data-path="/tarefa">
                  {Calendar3}
                  <p>Tarefas</p>
                  <div className="floater" />
                </li>
              </Link>
            </ul>
          </div>
          <section className="itensSideBar">
            <ul>
              <li className="config">
                {Bell}
                <p>Notificações</p>
                <div className="floater"></div>
              </li>
              <li className="config">
                {Gear}
                <p>Configurações</p>
                <div className="floater"></div>
              </li>
            </ul>
          </section>
          <section className="itensSideBar">
            <ul>
              <li className="loggoutSideBar config" onClick={handlelogout}>
                {ArrowBarRight}
                <p>Sair</p>
                <div className="floater"></div>
              </li>
            </ul>
          </section>
        </div>
        <button className="hideSideBar" onClick={hideSideBar}>
          <p>{CaretRightFill}</p>
        </button>
        <button
          className="fullScreenBTN"
          onClick={() => {
            setFullScreen(!fullSCreen);
          }}
          data-fullscreen={fullSCreen}
        >
          <p>{fullSCreen ? icons.FullscreenExit : icons.FullScreenEnter}</p>
        </button>
        <div className="page-body">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default SideBar;
