import { useContext } from "react";
import { AuthContext } from "./Auth";
import { Navigate } from "react-router";

const RequiredAuth = ({ children }: { children: JSX.Element }) => {
  const { loading, authenticated } = useContext(AuthContext);

  if (loading) return <div>Carregando...</div>;

  if (!authenticated) return <Navigate to="/login" />;

  return children;
};

export default RequiredAuth;
