import { FormEvent, useContext, useState } from "react";
import { AuthContext } from "@/Contexts/Auth";
import logoComNome from "../assets/logoComNome.svg";
import { Link } from "react-router-dom";

/**
 * Geração da inforção que contém login
 */
const LoginPage = () => {
  // Busca informações de login no contexto do user
  const { login } = useContext(AuthContext);

  // informações vazias
  const [email, setEmail] = useState("");
  // informações cheias
  const [password, setPassword] = useState("");

  /**
   * Faz a chamada da informação do form e passa a function login
   * @param event
   */
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    login(email, password);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="container login">
          <div className="topRight"></div>
          <div className="bottomLeft"></div>
          <div className="divLogo">
            <img src={logoComNome} alt="Monitorou" />
          </div>
          <section className="login-input input">
            <label htmlFor="email" className="txtEmail">
              E-mail
            </label>
            <input
              className="inputEmail"
              required
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </section>
          <section className="login-input input">
            <label htmlFor="password" className="txtSenha">
              Senha
            </label>
            <input
              className="inputSenha"
              required
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </section>
          <button type="submit" className="btnEntrar">
            Entrar
          </button>

          <section className="alternativos">
            <Link to="redefinir">Esqueci a senha</Link>
            {/* <Link to="/cadastro">Crie sua conta</Link> */}
          </section>

          <section className="bottom">® MONITOROU</section>
        </div>
      </form>
    </>
  );
};

export default LoginPage;
