import { ChecagemError } from "@/clients/Api";
import { CriarUsuario } from "@/clients/CriarUser";
import { FormEvent, useState } from "react";

import { useNavigate } from "react-router-dom";

import { icons } from "@/assets/icons";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { swalConfig } from "@/css/Padroes";

const CadastroPage: React.FC = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState<string>("");
  const [CNPJ, setCNPJ] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { CaretRightFill } = icons;

  // Mascara do CNPJ
  const cnpjMask = (value: string) => {
    return value
      .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números
  };

  // Remove a mascara do CNPJ
  const cnpjMaskRemove = (value: string) => {
    return value.replace(/\D+/g, "");
  };

  //  Faz a requisição de criação de usuário
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await CriarUsuario(nome, email, password, cnpjMaskRemove(CNPJ));
      navigate("/login");
    } catch (error) {
      const err = ChecagemError(error);

      Swal.fire({
        ...swalConfig,
        title: "Erro ao criar usuário",
        icon: "error",
        text: err.error,
      });
    }
  };

  return (
    <>
      <div className="cadastro login">
        <Link to="/login" className="backBTN">
          {CaretRightFill}
          <p>Voltar</p>
        </Link>
        <h2>Olá, vamos começar!</h2>
        <form className="form" onSubmit={handleSubmit}>
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            name="nome"
            onChange={(e) => setNome(e.target.value)}
            required
          />

          <label htmlFor="CNPJ">CPF / CNPJ</label>
          <input
            type="text"
            id="CNPJ"
            name="CNPJ"
            required
            value={cnpjMask(CNPJ)}
            onChange={(e) => setCNPJ(e.target.value)}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="senha">Senha</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <sub>
            Ao criar uma conta conosco, você concorda com nossos Temos e
            <span>política de privacidade</span>
          </sub>
          <button type="submit">Criar conta</button>
        </form>
      </div>
    </>
  );
};

export default CadastroPage;
