import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export interface IObjectResponse {
  code: number;
  message: string;
}

export const ChecagemError = (
  error: unknown
): { error: string; code: number } => {
  if (error instanceof AxiosError) {
    const err = error as AxiosError;
    if (err.response?.status === 401) throw window.location.replace("/login");
    else if (err.response && err.response.data) {
      const data: { error?: string } = err.response.data;

      return {
        error: JSON.stringify(data.error),
        code: err.response.status,
      };
    }
  }

  return { error: (error as Error).message, code: 500 };
};

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface RequestConfig extends AxiosRequestConfig {}
// extende e mascara o axiosRequestConfig
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Response<T = any> extends AxiosResponse<T> {}

/**
 * Configuração base do AXIOS para api distancia
 */

class ClassApi {
  public request;
  constructor(url = "https://monitorou-api.herokuapp.com") {
    this.request = axios.create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        
      },
    });
  }

  /**
   * Faz a validação
   * @param url subpagina da api
   * @param data dados a serem enviados
   * @returns
   */
  public async post<T>(url: string, data: unknown): Promise<Response<T>> {
    return await this.request.post(url, data).catch((err) => {
      if (
        err.response.status === 401 &&
        err.response.data.error == "invalid token"
      ) {
        this.token = null;
        throw window.location.replace("/login");
      }
      throw err;
    });
  }

  /**
   * Faz uma requisição tipo get
   * @param url
   */
  public async get<T>(url: string): Promise<Response<T>> {
    return await this.request.get(url).catch((err) => {
      if (
        err.response.status === 401 &&
        err.response.data.error == "invalid token"
      ) {
        this.token = null;
        throw window.location.replace("/login");
      }
      throw err;
    });
  }

  /**
   *  Faz uma requisição tipo patch
   */
  public async patch<T>(url: string, data: unknown): Promise<Response<T>> {
    return await this.request.patch(url, data).catch((err) => {
      if (
        err.response.status === 401 &&
        err.response.data.error == "invalid token"
      ) {
        this.token = null;
        throw window.location.replace("/login");
      }
      throw err;
    });
  }

  set token(token: string | null) {
    this.request.defaults.headers["x-acess-token"] = token;
  }
}

export const api = new ClassApi();

/**
 * Faz a busca do token
 * @returns response da api
 */
export const CreateSession = async (email: string, password: string) => {
  
  return (await api.post<{ apelido: string; token: string }>(
    "/user/authenticate",
    {
      email,
      password,
    },
  ));
};
