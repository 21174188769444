import { api } from "./Api";

/**
 * Interface para o status dos processos
 * dever ser usado em array de 5 possições
 */
export interface IStatus {
  nome: string;
  mesAno: string;
  total: number;
  resultado: {
    PENDENTE: number;
    LISTADO: number;
    INICIADO: number;
    FALHA: number;
    SUCESSO: number;
  };
}

/**
 * Interface para o calendario, deve ser usado
 * Array que deve conter sempre 5 posições
 */
export interface ICalendario {
  dia: number;
  processos: string[];
}

interface StatusRenspose {
  code: number;
  status: IStatus[];
  calendario: ICalendario[];
}

/**
 * Faz a busca dos status dos processos
 *
 * @returns Retornar um objeto com os status e o calendario
 */
export const recuperaStatus = async (): Promise<{
  status: IStatus[];
  calendario: ICalendario[];
}> => {
  const { status, calendario } = (await api.get<StatusRenspose>("/status"))
    .data;
  return { status, calendario };
};
