/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import LoginPage from "@/pages/UserLoginPage";
import HomePage from "@/pages/HomePage";
import { AuthProvider } from "@/Contexts/AuthProvider";
import CadastroPage from "./pages/UserCadastroPage";
import ParticipantePage from "./pages/ParticipanteListaPage";
import CriarParticipante from "./pages/ParticipanteCriarPage";
import RequiredAuth from "./Contexts/RequiredAuth";
import SideBar from "./components/SideBar";
import ProcessoListaPage from "./pages/ProcessoListaPage";
import ProcessoCriarPage from "./pages/ProcessoCreatePage";
import TarefaCriarPage from "./pages/TarafaCriarPage";
import TarefaListaPage from "./pages/TarefaListaPage";
import ProcessoEditar from "./pages/ProcessoEditar";
import RedefinirSenha from "./pages/RedefinirSenha";
import FormSenha from "./pages/RedefinirSenhaForm";

/**
 * Faz a manutenção das rotas da aplicaçãoa
 */
const AppRoutes = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/login/redefinir" element={<RedefinirSenha />}></Route>
          <Route path="/reseta-senha/:token" element={<FormSenha />}></Route>
          <Route path="/cadastro" element={<CadastroPage />}></Route>
          <Route
            path="/"
            element={
              <RequiredAuth>
                <SideBar />
              </RequiredAuth>
            }
          >
            <Route index element={<HomePage />} />
            <Route path="/participante" element={<ParticipantePage />} />
            <Route path="/participante/criar" element={<CriarParticipante />} />
            <Route path="/processo" element={<ProcessoListaPage />} />
            <Route path="/processo/:id" element={<ProcessoEditar />} />
            <Route path="/processo/criar" element={<ProcessoCriarPage />} />
            <Route path="/tarefa" element={<TarefaListaPage />} />
            <Route path="/tarefa/criar" element={<TarefaCriarPage />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
