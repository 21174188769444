import { IObjectResponse, api } from "./Api";

export interface IProcesso {
  userId: string;
  nome: string;
  dia: number;
  descricao: string;
  ativo: boolean;
  listaEmail: string;
  suporteEmail: string;
  id: string;
}

/**
 * Faz a busca de todos os processos
 */
export const recuperaProcessos = async (): Promise<IProcesso[]> => {
  return (await api.get<IProcesso[]>("/processo")).data;
};

export const recuperaProcessoUnico = async (id: string): Promise<IProcesso> => {
  return (
    await api.get<{ status: number; processo: IProcesso }>(`/processo/${id}`)
  ).data.processo;
};

export const criarProcesso = async (
  nome: string,
  dia: number,
  descricao: string,
  listaEmail: string,
  suporteEmail: string
): Promise<void> => {
  await api.post<IObjectResponse>("/processo", {
    nome,
    dia,
    descricao,
    listaEmail,
    suporteEmail,
  });
};

interface IeditarProcesso {
  nome?: string;
  dia?: number;
  descricao?: string;
  listaEmail?: string;
  suporteEmail?: string;
}

export const editarProcesso = async (
  id: string,
  data: IeditarProcesso
): Promise<void> => {
  await api.patch<IObjectResponse>(`/processo/${id}`, {
    ...data,
  });
};
