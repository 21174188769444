import { icons } from "@/assets/icons";
import MostrarProcessos from "@/components/ProcessosMostrar";

const ProcessoListaPage: React.FC = () => {
  const { Search } = icons;

  return (
    <>
      <header className="participantes lista">
        <h1>PROCESSOS </h1>
        <div>
          <div className="search">
            <input type="text" placeholder="Pesquisar"></input>
            {Search}
          </div>
          <a href="/processo/criar" className="addBTN">
            <button>
              <p>+</p>
            </button>
          </a>
        </div>
      </header>
      <div className="lista-wrapper">
        <MostrarProcessos />
      </div>
    </>
  );
};

export default ProcessoListaPage;
