import { icons } from "@/assets/icons";
import MostrarParticipantes from "@/components/ParticipantesMostrar";

const ParticipantePage: React.FC = () => {
  const { Search } = icons;

  return (
    <>
      <header className="participantes lista">
        <h1>PARTICIPANTES</h1>
        <div>
          <div className="search">
            <input type="text" placeholder="Pesquisar"></input>
            {Search}
          </div>
          <a href="/participante/criar" className="addBTN">
            <button>
              <p>+</p>
            </button>
          </a>
        </div>
      </header>
      <div className="lista-wrapper participantes">
        {<MostrarParticipantes />}
      </div>
    </>
  );
};

export default ParticipantePage;
