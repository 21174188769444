import { ChecagemError } from "@/clients/Api";
import { criaParticipante } from "@/clients/Participante";
import { swalConfig } from "@/css/Padroes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CriarParticipante: React.FC = () => {
  const navigate = useNavigate();

  const [nome, setNome] = useState<string>("");
  const [identificador, setIdentificador] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const showSuccess = () => {
      Swal.fire({
        ...swalConfig,
        text: "Seu cadastro foi concluído! ",
        icon: "success",
        confirmButtonText: "Voltar",
        showCancelButton: true,
        cancelButtonText: "Continuar cadastrando",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/participante");
        }
      });
    };

    const showError = (message: string) => {
      Swal.fire({
        ...swalConfig,
        title: "Erro!",
        text: message,
        icon: "error",
        confirmButtonText: "Voltar",
        showCancelButton: true,
        cancelButtonText: "Continuar cadastrando",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/participante");
        }
      });
    };

    try {
      await criaParticipante(nome, identificador, email).then((response) => {
        const { message } = response;

        if (message == "Criado") {
          showSuccess();
        }
      });
    } catch (error) {
      const err = ChecagemError(error);

      showError(err.error);
    }
  };

  return (
    <div className="criarParticipantes">
      <h1>CADASTRO DE PARTICIPANTES</h1>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Nome
              <span>*</span>
            </label>
            <input
              type="text"
              name="nome"
              required
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div>
            <label>
              Identificador <span>*</span>
            </label>
            <input
              type="text"
              name="identificador"
              required
              onChange={(e) => setIdentificador(e.target.value)}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <button type="submit">Cadastrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CriarParticipante;
