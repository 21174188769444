import { icons } from "@/assets/icons";
import {
  IProcesso,
  editarProcesso,
  recuperaProcessoUnico,
} from "@/clients/Processo";
import { regexExp } from "@/clients/regxp";
import { swalConfig } from "@/css/Padroes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ProcessoEditar = () => {
  interface IProcessoEditar {
    nome?: string;
    listaEmail?: string;
    suporteEmail?: string;
    dia?: number;
    descricao?: string;
  }

  const { id } = useParams();
  const [processo, setProcesso] = useState<IProcesso>();

  const oldData = processo;

  const [name, setName] = useState<string>("");
  const [listaEmail, setListaEmail] = useState<string>("");
  const [suporteEmail, setSuporteEmail] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");

  const [listaEmails, setListaEmails] = useState<string>("");
  const [suporteEmails, setSuporteEmails] = useState<string>("");

  const [setInput, setSetInput] = useState<boolean>(true);

  const attLiEmails = () => {
    const allLiEmail = document.querySelectorAll(
      "p.liEmail"
    ) as NodeListOf<HTMLDivElement>;

    setListaEmails(
      Array.from(allLiEmail)
        .map((li) => li.innerHTML)
        .join(";")
    );
  };

  const attSupEmails = () => {
    const allSupEmail = document.querySelectorAll(
      "p.supEmail"
    ) as NodeListOf<HTMLDivElement>;

    setSuporteEmails(
      Array.from(allSupEmail)
        .map((sup) => sup.innerHTML)
        .join(";")
    );
  };

  const removeEmail = (e: MouseEvent) => {
    const target = e.target as HTMLDivElement;
    const parent = target.parentElement?.parentElement as HTMLDivElement;
    parent.remove();

    attSupEmails();
    attLiEmails();
  };

  useEffect(() => {
    if (!id) {
      Swal.fire({
        ...swalConfig,
        title: "Erro!",
        text: "algo deu errado, tente novamente mais tarde",
        icon: "error",
      }).then(() => window.location.reload());
      return;
    }

    recuperaProcessoUnico(id)
      .then((processo) => setProcesso(processo))
      .catch((err) => Swal.fire({ ...swalConfig, icon: "error", text: err }));
  }, []);

  useEffect(() => {
    if (!processo) return;

    if (Object.values(processo).length === 0) {
      return;
    }

    const { nome, listaEmail, suporteEmail, descricao } = processo;

    setName(nome);
    setListaEmails(listaEmail);
    setSuporteEmails(suporteEmail);
    setDescricao(descricao);
  }, [processo]);

  useEffect(() => {
    if (!listaEmails || !suporteEmails || !setInput) return;

    const LiEwrapper = document.querySelector(
      ".input-wrapper.liEmail"
    ) as HTMLDivElement;
    const SupEwrapper = document.querySelector(
      ".input-wrapper.supEmail"
    ) as HTMLDivElement;

    if (!LiEwrapper || !SupEwrapper) return;

    const addLi = document.createElement("div");
    const addSup = document.createElement("div");

    addLi.classList.add("setLiEmail");
    addSup.classList.add("setSupEmail");

    const allLiEmail = listaEmails.split(";");
    const allSupEmail = suporteEmails.split(";");

    allLiEmail.forEach((li) => {
      addLi.innerHTML = `<div class="inner"><p class="liEmail">${li}</p><small class="minus"></small></div>`;
      const clone = addLi.cloneNode(true) as HTMLDivElement;

      LiEwrapper.appendChild(clone);
    });

    allSupEmail.forEach((sup) => {
      addSup.innerHTML = `<div class="inner"><p class="supEmail">${sup}</p><small class="minus"></small></div>`;
      const clone = addSup.cloneNode(true) as HTMLDivElement;

      SupEwrapper.appendChild(clone);
    });

    const allMinus = document.querySelectorAll(
      ".minus"
    ) as NodeListOf<HTMLDivElement>;

    allMinus.forEach((minus) => {
      minus.removeEventListener("click", (e) => removeEmail(e), false);
    });

    allMinus.forEach((minus) => {
      minus.addEventListener("click", (e) => removeEmail(e), false);
    });

    setSetInput(false);
  }, [listaEmails, suporteEmails]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const nome = e.currentTarget.querySelector(
      "input[name=nome]"
    ) as HTMLInputElement;
    const select = e.currentTarget.querySelector("select") as HTMLSelectElement;
    const textArea = e.currentTarget.querySelector(
      "textarea"
    ) as HTMLTextAreaElement;

    const newData = [] as IProcessoEditar[];

    newData.push({ nome: nome.value });
    newData.push({ dia: Number(select.value) });
    newData.push({ descricao: textArea.value });
    newData.push({ listaEmail: listaEmails });
    newData.push({ suporteEmail: suporteEmails });

    const willSend = newData.filter((data) => {
      const key = Object.keys(data)[0] as keyof IProcessoEditar;
      const value = Object.values(data)[0];

      if (value === undefined) return;
      if (oldData === undefined) return;

      if (oldData[key] !== value) {
        return data;
      }
    });

    if (willSend.length === 0) {
      const dataToSend = Object.assign({}, ...willSend);
      console.log(dataToSend, "dataToSend");
      console.log(newData, "allData");
      return Swal.fire({
        title: "Nada foi alterado",
        icon: "warning",
        confirmButtonText: "Ok",
        ...swalConfig,
      });
    } else {
      const dataToSend = Object.assign({}, ...willSend);
      console.log(dataToSend, "dataToSend");

      if (!id)
        return Swal.fire({
          ...swalConfig,
          icon: "error",
          text: "algo deu errado, tente novamente mais tarde",
        });
      editarProcesso(id, dataToSend).then(() => {
        Swal.fire({
          title: "Processo atualizado com sucesso",
          icon: "success",
          confirmButtonText: "Ok",
          cancelButtonColor: "#d33",
          cancelButtonText: "voltar para a lista",
          ...swalConfig,
        }).then(() => window.location.reload());
      });
    }
  };

  const dias = [];
  for (let i = 1; i <= 31; i++) {
    dias.push(i);
  }

  const opcDias = dias.map((dia, i) => {
    if (dia == processo?.dia) {
      return (
        <option value={dia} key={i} selected>
          {dia}
        </option>
      );
    } else {
      return (
        <option value={dia} key={i}>
          {dia}
        </option>
      );
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, k: string) => {
    if (!oldData) return;

    const { target } = e;

    const key = k as keyof IProcessoEditar;

    if (e.target.value !== oldData[key]) {
      target.classList.add("changed");
      return;
    } else {
      target.classList.remove("changed");
      return;
    }
  };

  const addLiEmail = () => {
    const liEmail = document.querySelector(".liEmail");
    const listaEmailElement = document.querySelector(
      "#listaEmail"
    ) as HTMLInputElement;

    const check = regexExp.test(listaEmailElement.value);

    if (!liEmail || listaEmailElement.value == "" || !check) {
      console.log(listaEmailElement.value);
      console.log(check);
      console.log(liEmail);

      Swal.fire({
        ...swalConfig,
        title: "email inválido ou vazio",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const add = document.createElement("div");
    add.className = "setLiEmail";
    listaEmailElement
      ? (add.innerHTML = `<div class="inner"><p class="liEmail">${listaEmailElement.value}</p><small class="minus"></small></div>`)
      : null;
    liEmail?.appendChild(add);

    const allMinus = document.querySelectorAll(
      ".minus"
    ) as NodeListOf<HTMLDivElement>;

    allMinus.forEach((minus) => {
      minus.removeEventListener("click", (e) => removeEmail(e), false);
    });

    allMinus.forEach((minus) => {
      minus.addEventListener("click", (e) => removeEmail(e), false);
    });

    attLiEmails();

    setListaEmail("");
  };

  const AddSup = () => {
    const supEmail = document.querySelector(".supEmail");
    const suporteEmailElement = document.querySelector(
      "#suporteEmail"
    ) as HTMLInputElement;

    const check = regexExp.test(suporteEmailElement.value);

    if (!supEmail || suporteEmailElement.value == "" || !check) {
      Swal.fire({
        ...swalConfig,
        title: "email inválido ou vazio",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const add = document.createElement("div");
    add.className = "setSupEmail";
    suporteEmailElement
      ? (add.innerHTML = `<div class="inner"><p class="supEmail">${suporteEmailElement.value}</p><small class="minus"></small></div>`)
      : null;
    supEmail?.appendChild(add);

    const allMinus = document.querySelectorAll(
      ".minus"
    ) as NodeListOf<HTMLDivElement>;

    allMinus.forEach((minus) => {
      minus.removeEventListener("click", (e) => removeEmail(e), false);
    });

    allMinus.forEach((minus) => {
      minus.addEventListener("click", (e) => removeEmail(e), false);
    });

    attSupEmails();

    setSuporteEmail("");
  };

  return (
    <div className="criarProcesso">
      <h1>Editar processo</h1>

      {processo ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="top">
              <div className="left">
                <label>nome</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="nome"
                    id="nome"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      handleChange(e, "nome");
                    }}
                    required
                  />
                </div>

                <label>Lista E-mail</label>
                <div className="input-wrapper liEmail">
                  <div className="first">
                    <input
                      type="text"
                      name="listaEmail"
                      id="listaEmail"
                      value={listaEmail}
                      onChange={(e) => setListaEmail(e.target.value)}
                    />
                    <div className="add" onClick={addLiEmail}>
                      {icons.PlusLg}
                    </div>
                  </div>
                </div>
                <label>Suporte E-mail</label>
                <div className="input-wrapper supEmail">
                  <div className="first">
                    <input
                      type="text"
                      name="suporteEmail"
                      id="suporteEmail"
                      value={suporteEmail}
                      onChange={(e) => setSuporteEmail(e.target.value)}
                    />
                    <div className="add" onClick={AddSup}>
                      {icons.PlusLg}
                    </div>
                  </div>
                </div>
                <label>Descrição</label>
                <textarea
                  name="descricao"
                  id="descricao"
                  cols={30}
                  rows={4}
                  maxLength={150}
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  required
                />
              </div>

              <div className="right">
                <section>
                  <label>dia</label>
                  <select name="dia" id="dia">
                    {opcDias}
                  </select>
                </section>
              </div>

              <button type="submit">atualizar</button>
            </div>
          </form>
        </>
      ) : (
        <div>Carregando...</div>
      )}
    </div>
  );
};

export default ProcessoEditar;
