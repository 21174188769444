import {
  ArrowBarRight,
  ArrowsFullscreen,
  Bell,
  Calendar3,
  CaretDownFill,
  CaretRightFill,
  DashLg,
  FullscreenExit,
  Gear,
  HouseDoorFill,
  JournalBookmark,
  PencilSquare,
  PersonCircle,
  PlusLg,
  Search,
} from "react-bootstrap-icons";

const icons = {
  HouseDoorFill: <HouseDoorFill />,
  PersonCircle: <PersonCircle />,
  JournalBookmark: <JournalBookmark />,
  Calendar3: <Calendar3 />,
  Bell: <Bell />,
  Gear: <Gear />,
  CaretDownFill: <CaretDownFill />,
  CaretRightFill: <CaretRightFill />,
  ArrowBarRight: <ArrowBarRight />,
  Search: <Search />,
  PlusLg: <PlusLg />,
  DashLg: <DashLg />,
  FullScreenEnter: <ArrowsFullscreen />,
  FullscreenExit: <FullscreenExit />,
  PencilSquare: <PencilSquare />,
};

export { icons };
