import { icons } from "@/assets/icons";
import { FormEvent, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../assets/logoComNome.svg";
import { checkToken, resetarSenha } from "@/clients/RedefinirSenha";
import Swal from "sweetalert2";
import { swalConfig } from "@/css/Padroes";

const FormSenha = () => {
  interface IResponse {
    code: number;
    message: boolean;
  }

  const [senha1, setSenha1] = useState("");
  const [senha2, setSenha2] = useState("");

  const tk = useParams();
  const token = tk.token as string;

  useEffect(() => {
    if (!token) {
      //   window.location.href = "/login";
      return;
    }

    checkToken(token).then((res) => {
      const response = res as IResponse;
      if (!response.message) {
        window.location.href = "/login";
      }
    });
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (senha1 !== senha2) {
      Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Oops...",
        text: "As senhas não coincidem!",
      });
      return;
    }

    if (senha1.length < 6 || senha2.length < 6) {
      Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Oops...",
        text: "A senha deve ter no mínimo 6 caracteres!",
      });
      return;
    }

    checkToken(token).then((res) => {
      const response = res as IResponse;
      if (!response.message) {
        window.location.href = "/login";
      }
    });

    resetarSenha(token, senha1).then(() => {
      Swal.fire({
        icon: "success",
        title: "Senha redefinida!",
        text: "Sua senha foi redefinida com sucesso!",
        ...swalConfig,
      }).then(() => {
        window.location.href = "/login";
      });
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="container login redefinir">
        <Link to="/login" className="backBTN">
          {icons.CaretRightFill}
          <p>Voltar</p>
        </Link>
        <h1>Redefinir senha por E-mail</h1>
        <div className="divLogo">
          <img src={logo} alt="Monitorou" />
        </div>
        <section className="login-input input">
          <label htmlFor="password" className="txtPassword">
            Senha
          </label>
          <input
            className="inputSenha"
            required
            type="password"
            name="password"
            id="password1"
            value={senha1}
            onChange={(e) => setSenha1(e.target.value)}
          />
        </section>
        <section className="login-input input">
          <label htmlFor="password" className="txtPassword">
            Confirmar senha
          </label>
          <input
            className="inputSenha"
            required
            type="password"
            name="password"
            id="password1"
            value={senha2}
            onChange={(e) => setSenha2(e.target.value)}
          />
        </section>

        <button type="submit" className="btnEntrar">
          Confirmar troca
        </button>
      </div>
    </form>
  );
};

export default FormSenha;
