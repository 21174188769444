import { IProcesso, recuperaProcessos } from "@/clients/Processo";
import { ITarefa, recuperaTarefas } from "@/clients/Tarefa";
import {
  Status,
  opcoesAnos,
  opcoesMeses,
  opcoesStatus,
} from "@/util/comum-todos";
import { useEffect, useState } from "react";

const TarefaListaPage: React.FC = () => {
  const [processos, setProcessos] = useState<IProcesso[]>([]);
  const [processo, setProcesso] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.TODOS);
  const [mes, setMes] = useState<string>("01");
  const [ano, setAno] = useState<string>("2021");

  const [tarefas, setTarefas] = useState<ITarefa[]>([]);

  useEffect(() => {
    // Faz a requisição para o servidor e recupera os processos
    recuperaProcessos().then((processos) => {
      setProcessos(processos);
      setProcesso(processos[0].id);

      // Faz a requisição para o servidor e recupera as tarefas
      // recuperaTarefas(processos[0].id, mes, ano, status).then((tarefas) => {
      //   setTarefas(tarefas);
      // });
    });
  }, []);

  // Cria um array de opções para o select de processos
  const opcoesProcessos = processos.map((processo, i) => (
    <>
      <option key={i} value={processo.id}>
        {processo.nome}
      </option>
    </>
  ));

  // Seta a requisição para o servidor e recupera as tarefas
  const handleSubmit = async () =>
    setTarefas(await recuperaTarefas(processo, mes, ano, status));

  const showDetalhes = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { currentTarget } = e;

    const escondido = currentTarget.parentElement?.parentElement?.querySelector(
      ".escondidoTarefa"
    ) as HTMLDivElement;

    if (escondido) {
      escondido.classList.toggle("active");
    }

    console.log(escondido);
  };

  // Cria um array de opções para o select de status
  const listaTarefas = tarefas.map((tarefa, index) => {
    const data = new Date(tarefa.dataCriacao);
    const horario = data.getHours() + ":" + data.getMinutes();

    const dataConclusao = new Date(tarefa.dataConclusao || "") as Date | "";

    const horarioConclusao =
      dataConclusao === ""
        ? "---"
        : dataConclusao.getHours() + ":" + dataConclusao.getMinutes();

    return (
      <div key={index} className="item-wrapper">
        <div className="lista-item" key={index}>
          <div className={"status " + tarefa.status}>
            <p>{tarefa.status}</p>
          </div>
          <section className="left">
            <div>
              <label>NOME</label>
              <p className="nome">{tarefa.nome}</p>
            </div>

            <div className="inline">
              <div>
                <label>DATA CRIAÇÃO</label>
                <p>
                  {tarefa.dataCriacao
                    .toString()
                    .substring(0, 10)
                    .replaceAll("-", "/")}
                </p>
              </div>
              <div>
                <label>HORARIO</label>
                <p>{horario}</p>
              </div>
            </div>
            <div>
              <label>Descrição</label>
              <p>{tarefa.descricao || "---"}</p>
            </div>
            <div className="inline">
              <div>
                <label>DATA Conclusão</label>
                <p>
                  {tarefa.dataConclusao
                    ?.toString()
                    .substring(0, 10)
                    .replaceAll("-", "/") || "---"}
                </p>
              </div>
              <div>
                <label>HORARIO Conclusão</label>
                <p>{tarefa.dataError ? horarioConclusao.toString() : "---"}</p>
              </div>
            </div>
          </section>

          <section className="right">
            <div className="inline">
              <label>ID</label>
              <p>{tarefa.identificador}</p>
            </div>
            <div>
              <label>Mês / Ano</label>
              <p>{tarefa.mesAno}</p>
            </div>
          </section>

          <button className="detalhes" onClick={showDetalhes}>
            Motivo Error
          </button>
        </div>
        <div className="escondidoTarefa">
          <div>
            <label>Anexo</label>
            <p>{tarefa.anexo}</p>
          </div>
          <div>
            <label>Data Error</label>
            <p>{tarefa.dataError?.toString() || "---"}</p>
          </div>
          <div className="error">
            <label>Motivo error</label>
            <p>{tarefa.motivoError || "---"}</p>
          </div>
        </div>
      </div>
    );
  });

  // @TODO deixar mais bonito
  return (
    <>
      <header className="tarefas">
        <h1>TAREFAS</h1>

        <section>
          <div className="search">
            {/* <input type="text" name="" id="" placeholder="NÃO FUNCIONAL" /> */}

            <a href="/tarefa/criar" className="addBTN">
              <p>+</p>
            </a>
          </div>

          <div className="selects">
            <select
              name="processo"
              id="processo"
              onChange={(e) => setProcesso(e.target.value)}
            >
              {opcoesProcessos}
            </select>
            <select
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value as Status)}
            >
              {opcoesStatus}
            </select>
            <select
              name="mes"
              id="mes"
              onChange={(e) => setMes(e.target.value)}
            >
              {opcoesMeses}
            </select>
            <select
              name="ano"
              id="ano"
              onChange={(e) => setAno(e.target.value)}
            >
              {opcoesAnos}
            </select>

            <button onClick={handleSubmit}>Buscar</button>
          </div>
        </section>
      </header>

      <div className="lista-wrapper tarefas">{listaTarefas}</div>
    </>
  );
};

export default TarefaListaPage;
