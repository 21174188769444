import { api } from "./Api";

export interface IParticipante {
  userId: string;
  nome: string;
  email?: string;
  identificador: string;
  id: string;
}

interface IResponseCriarP {
  code: number;
  message: string;
}

export const recuperaParticipante = async (): Promise<IParticipante[]> => {
  return (await api.get<IParticipante[]>("/participante")).data;
};

export const criaParticipante = async (
  nome: string,
  identificador: string,
  email?: string
) => {
  const response = await api.post("/participante", {
    nome,
    identificador,
    email,
  });

  return response.data as IResponseCriarP;
};
