import React from "react";
import { icons } from "@/assets/icons";
import LoadingBarsHome from "@/components/HomeLoadingBar";
import HomeEvents from "@/components/HomeEvent";

const HomePage: React.FC = () => {
  const { CaretRightFill } = icons;

  const closeEvents = ({
    target,
  }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const targetElement = target as HTMLDivElement;

    targetElement.classList.toggle("hidden");
  };

  return (
    <>
      <div className="busca home" id="distancia">
        <div className="home-container">
          <div className="main">
            <h1>
              DASHBOARD
              <span className="legenda">
                <span>
                  <div
                    className="color"
                    style={{ backgroundColor: "#FFF700" }}
                  />
                  <p>Pendente</p>
                </span>
                <span>
                  <div
                    className="color"
                    style={{ backgroundColor: "#1EDFAA" }}
                  />
                  <p>Listado</p>
                </span>
                <span>
                  <div
                    className="color"
                    style={{ backgroundColor: "#5F1EDF" }}
                  />
                  <p>Iniciado</p>
                </span>
                <span>
                  <div
                    className="color"
                    style={{ backgroundColor: "#E92626" }}
                  />
                  <p>Falha</p>
                </span>
                <span>
                  <div
                    className="color"
                    style={{ backgroundColor: "#4CDF1E" }}
                  />
                  <p>Sucesso</p>
                </span>
              </span>
            </h1>
            <LoadingBarsHome />
          </div>
          <div className="cel">
            <div
              className={
                "close" + " " + (window.innerWidth < 1300 ? "hidden" : "")
              }
              onClick={closeEvents}
            >
              {CaretRightFill}
            </div>
            <div className="inner">
              <HomeEvents />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
