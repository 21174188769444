import { icons } from "@/assets/icons";
import { ChecagemError } from "@/clients/Api";
import { IParticipante, recuperaParticipante } from "@/clients/Participante";
import { IProcesso, recuperaProcessos } from "@/clients/Processo";
import { criarTarefas } from "@/clients/Tarefa";
import { swalConfig } from "@/css/Padroes";
import { opcoesAnos, opcoesMeses } from "@/util/comum-todos";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

const TarefaCriarPage: React.FC = () => {
  const navigate = useNavigate();
  const [mes, setMes] = useState<string>("01");
  const [ano, setAno] = useState<string>("2021");
  const [processos, setProcessos] = useState<IProcesso[]>([]);
  const [processo, setProcesso] = useState<string>("");

  const [participantes, SetParticipantes] = useState<IParticipante[]>([]);

  const [participanteSelecionado, setParticipanteSelecionado] = useState<
    string[]
  >([]);

  useEffect(() => {
    // Faz a requisição para o servidor e recupera os processos
    recuperaParticipante().then((participantes) => {
      SetParticipantes(participantes);
    });

    // Faz a requisição para o servidor e recupera os processos
    recuperaProcessos().then((processos) => {
      setProcessos(processos);
      setProcesso(processos[0].id);
    });
  }, []);

  const opcoesProcessos = processos.map((processo) => (
    <>
      <option value={processo.id}>{processo.nome}</option>
    </>
  ));

  //  Faz a seleção dos participantes
  const handleParticipante = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = event.target;

    if (checked) {
      setParticipanteSelecionado([...participanteSelecionado, id]);
    } else {
      setParticipanteSelecionado(
        participanteSelecionado.filter((participante) => participante !== id)
      );
    }
  };

  //  Faz a seleção dos participantes
  const opcoesParticipantes = participantes.map((participante) => (
    <>
      <div>
        <li className="drop-content">
          {participante.nome}
          <input
            type="checkbox"
            id={participante.id}
            onChange={handleParticipante}
          />
        </li>
      </div>
    </>
  ));

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    // Verifica se algum participante foi selecionado
    if (participanteSelecionado.length === 0)
      return Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Oops...",
        text: "Selecione pelo menos um participante",
      });

    // Faz a criação
    try {
      await criarTarefas(processo, mes, ano, participanteSelecionado);
      Swal.fire({
        ...swalConfig,
        icon: "success",
        title: "Sucesso!",
        text: "Tarefa criada com sucesso",
      }).then((res) => {
        if (res.isConfirmed) navigate("/tarefas");
      });
    } catch (error) {
      const err = ChecagemError(error);
      console.log(err);
      Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Oops...",
        text: err.error,
      });
    }
  };

  return (
    <>
      <div className="criar-tarefa">
        <h1>Novas Tarefas</h1>
        <form onSubmit={onSubmit}>
          <div className="drop-wrapper">
            <label
              className="drop-btn"
              onClick={(e) => {
                e.currentTarget.classList.toggle("active");
              }}
            >
              Participante {icons.CaretDownFill}
            </label>
            <ul className="participantes-drop">{opcoesParticipantes}</ul>
          </div>

          <div>
            {/* <label>Processo </label> */}
            <select onChange={(e) => setProcesso(e.target.value)}>
              {opcoesProcessos}
            </select>
          </div>

          <div>
            {/* <label>Mês</label> */}
            <select onChange={(e) => setMes(e.target.value)}>
              {opcoesMeses}
            </select>
          </div>

          <div>
            {/* <label>Ano</label> */}
            <select onChange={(e) => setAno(e.target.value)}>
              {opcoesAnos}
            </select>
          </div>

          <button type="submit">Criar Tarefa</button>
        </form>
      </div>
    </>
  );
};

export default TarefaCriarPage;
