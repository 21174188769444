import { api } from "./Api";

export const enviarEmail = async (email: string) => {
  const response = await api.post(`/user/resetar-senha`, { email });

  return response.data;
};

export const checkToken = async (token: string) => {
  const response = await api.get(`/user/resetar-senha/${token}`);

  return response.data;
};

export const resetarSenha = async (token: string, password: string) => {
  const response = await api.post(`/user/resetar-senha/${token}`, { password });

  return response.data;
};
