import { Status } from "@/util/comum-todos";
import { IObjectResponse, api } from "./Api";

export interface ITarefa {
  id: string;
  userId: string;
  processoId: string;
  participanteId: string;
  status: Status;
  mesAno: string;
  dataCriacao: Date;
  dataExecucao?: Date;
  dataConclusao?: Date;
  dataError?: Date;
  motivoError?: string;
  anexo?: string;
  nome: string;
  identificador: string;
  descricao?: string;
}

export const recuperaTarefas = async (
  processoId: string,
  mes: string,
  ano: string,
  status: Status = Status.TODOS
): Promise<ITarefa[]> => {
  return (
    await api.get<{ code: number; message: string; tarefas: ITarefa[] }>(
      `/tarefa?processoId=${processoId}&mesAno=${mes + ano}`
    )
  ).data.tarefas.filter((t) => status === Status.TODOS || t.status === status);
};

export const criarTarefas = async (
  processoId: string,
  mes: string,
  ano: string,
  participantesId: string[]
) => {
  await api.post<IObjectResponse>(
    `/tarefa?processoId=${processoId}&mesAno=${mes + ano}`,
    participantesId
  );
};
