import { useEffect, useState } from "react";
import { ICalendario, recuperaStatus } from "@/clients/Status";
import { ChecagemError } from "@/clients/Api";
import Swal from "sweetalert2";
import { swalConfig } from "@/css/Padroes";

const HomeEvents = () => {
  const [calendario, setCalendario] = useState<Array<ICalendario>>([]);
  const [atualiza, setAtualiza] = useState(false);

  /**
   * @description
   * @param {number} dia - dia do evento
   * @param {string} descricao1 - primeira descrição do evento
   * @param {string} descricao2 - segunda descrição do evento
   */

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    recuperaStatus()
      .then((res) => {
        setCalendario(res.calendario);
      })
      .catch((err) => {
        const error = ChecagemError(err);

        console.log(error.error);

        Swal.fire({
          ...swalConfig,
          icon: "error",
          title: "Oops...",
          text: error.error.toString(),
          showConfirmButton: false,
          timer: 1500,
        });
      });

    delay(15000).then(() => setAtualiza(!atualiza));
  }, []);

  const handleEvents = calendario.map((item, index) => {
    return (
      <div className="event" key={index}>
        <div className="event-day">
          <h3>dia {item.dia < 10 ? "0" + item.dia : item.dia}:</h3>
        </div>
        <div className="event-description">
          <div className="descricao">
            <p>- {item.processos[0]}</p>
          </div>
          {item.processos[1] ? (
            <div className="descricao">
              <p>- {item.processos[1]}</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  });

  return <>{handleEvents}</>;
};

export default HomeEvents;
