import { FormEvent, useState } from "react";
import LogoComNome from "@/assets/logoComNome.svg";
import { Link } from "react-router-dom";
import { icons } from "@/assets/icons";
import { checkToken, enviarEmail } from "@/clients/RedefinirSenha";
import Swal from "sweetalert2";
import { swalConfig } from "@/css/Padroes";

const RedefinirSenha = () => {
  interface IResponse {
    code: number;
    message: boolean;
  }

  const [email, setEmail] = useState("");

  const navigate = (path: string) => {
    window.location.href = `/reseta-senha/${path}`;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Preencha o campo de e-mail!",
        ...swalConfig,
      });
      return;
    } else
      enviarEmail(email).then(() => {
        Swal.fire({
          icon: "success",
          title: "E-mail enviado!",
          text: "Entre no link da sua caixa de entrada para redefinir sua senha! Ou copie o código no campo abaixo!",
          input: "text",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          ...swalConfig,
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value !== "") {
              checkToken(result.value).then((res) => {
                const response = res as IResponse;
                if (response.message) {
                  navigate(result.value);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Código inválido!",
                    ...swalConfig,
                  });
                }
              });
            }
          }
        });
      });
  };
  //

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="container login redefinir">
        <Link to="/login" className="backBTN">
          {icons.CaretRightFill}
          <p>Voltar</p>
        </Link>
        <h1>Redefinir senha por E-mail</h1>
        <div className="divLogo">
          <img src={LogoComNome} alt="Monitorou" />
        </div>
        <section className="login-input input">
          <label htmlFor="email" className="txtEmail">
            E-mail
          </label>
          <input
            className="inputEmail"
            required
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </section>

        <button type="submit" className="btnEntrar">
          Mandar E-mail
        </button>
      </div>
    </form>
  );
};

export default RedefinirSenha;
