import { IParticipante, recuperaParticipante } from "@/clients/Participante";
import { useState, useEffect } from "react";

const MostrarParticipantes = () => {
  const [participante, setParticipante] = useState<IParticipante[]>([]);

  useEffect(() => {
    recuperaParticipante()
      .then((participantes) => setParticipante(participantes))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const listaParticipantes = participante.map((participante) => (
    <div key={participante.id} className="lista-item">
      <div>
        <label>Nome</label>
        <p>{participante.nome}</p>
      </div>
      {participante.email ? (
        <div>
          <label>Email</label>
          <p>{participante.email}</p>
        </div>
      ) : (
        ""
      )}
      <div>
        <label>ID</label>
        <p>{participante.identificador}</p>
      </div>
    </div>
  ));
  return <>{listaParticipantes}</>;
};

export default MostrarParticipantes;
