import { icons } from "@/assets/icons";
import { IProcesso, recuperaProcessos } from "@/clients/Processo";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MostrarProcessos = () => {
  const [processo, setProcesso] = useState<IProcesso[]>([]);

  useEffect(() => {
    recuperaProcessos().then((processos) => setProcesso(processos));
  }, []);

  const listaProcessos = processo.map((processo) => (
    <>
      <div key={processo.id} className="lista-item processos">
        <div className="editar">
          <Link to={"/processo/" + processo.id}>{icons.PencilSquare}</Link>
        </div>
        <div>
          <label>NOME</label>
          <p>{processo.nome}</p>
        </div>
        {processo.listaEmail ? (
          <div>
            <label>Email</label>
            <p>
              {processo.listaEmail.split(":").map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
            </p>
          </div>
        ) : (
          ""
        )}
        <div>
          <label>Email Suporte</label>
          <p>
            {processo.suporteEmail.split(";").map((value, index) => {
              return <p key={index}>{value}</p>;
            })}
          </p>
        </div>
        <div>
          <label>ID</label>
          <p
            onClick={() => {
              // @TODO @rodrigoSET - implementar ação de copiar o ID
            }}
          >
            {processo.id}
          </p>
        </div>
        <div>
          <label>Dia</label>
          <p className="dia">{processo.dia}</p>
        </div>
      </div>
    </>
  ));

  return <>{listaProcessos}</>;
};

export default MostrarProcessos;
