export enum Status {
  FALHA = "FALHA",
  SUCESSO = "SUCESSO",
  PENDENTE = "PENDENTE",
  INICIADO = "INICIADO",
  LISTADO = "LISTADO",
  TODOS = "TODOS",
}

const anos = [];

for (let i = 2021; i <= 2030; i++) {
  anos.push(i);
}

export const opcoesAnos = anos.map((ano, i) => (
  <>
    <option key={i} value={ano}>
      {ano}
    </option>
  </>
));

const meses = [];

for (let i = 1; i <= 12; i++) {
  const mes = i < 10 ? `0${i}` : i;
  meses.push(mes);
}

export const opcoesMeses = meses.map((mes, i) => (
  <>
    <option key={i} value={mes}>
      {mes}
    </option>
  </>
));

export const opcoesStatus = Object.keys(Status).map((status, i) => (
  <>
    <option key={i} value={status}>
      {status}
    </option>
  </>
));
