import { ChecagemError } from "@/clients/Api";
import { criarProcesso } from "@/clients/Processo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "@/assets/icons";
import Swal from "sweetalert2";
import { swalConfig } from "@/css/Padroes";

const ProcessoCriarPage: React.FC = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState<string>("");
  const [listaEmails, setListaEmails] = useState<string>("");
  const [suporteEmail, setSuporteEmail] = useState<string>("");
  const [dia, setDia] = useState<number>(1);
  const [descricao, setDescricao] = useState<string>("");

  const { PlusLg } = icons;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (nome == "" || listaEmails == "" || suporteEmail == "") {
      Swal.fire({
        title: "Erro ao criar processo",
        icon: "error",
        text: "Preencha todos os campos",
        confirmButtonText: "ok",
        ...swalConfig,
      });
      return;
    }

    try {
      await criarProcesso(nome, dia, descricao, listaEmails, suporteEmail);
    } catch (error) {
      const err = ChecagemError(error);
      Swal.fire({
        title: "Erro ao criar processo",
        icon: "error",
        text: err.error,
        confirmButtonText: "ok",
        ...swalConfig,
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
      return;
    }

    Swal.fire({
      title: "Processo criado com sucesso!",
      icon: "success",
      confirmButtonText: "voltar para a lista de processos",
      cancelButtonText: "criar outro processo",
      cancelButtonColor: "#56309a",
      ...swalConfig,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/processo");
      }
    });
  };

  const dias = [];
  for (let i = 1; i <= 31; i++) {
    dias.push(i);
  }

  const opcoes = dias.map((dia) => (
    <>
      <option value={dia}>{dia}</option>
    </>
  ));

  const attLiEmails = () => {
    const allLiEmail = document.querySelectorAll(
      "p.liEmail"
    ) as NodeListOf<HTMLDivElement>;

    setListaEmails(
      Array.from(allLiEmail)
        .map((li) => li.innerHTML)
        .join(";")
    );
  };

  const attSupEmails = () => {
    const allSupEmail = document.querySelectorAll(
      "p.supEmail"
    ) as NodeListOf<HTMLDivElement>;

    setSuporteEmail(
      Array.from(allSupEmail)
        .map((sup) => sup.innerHTML)
        .join(";")
    );
  };

  const removeEmail = (e: MouseEvent) => {
    const target = e.target as HTMLDivElement;
    const parent = target.parentElement?.parentElement as HTMLDivElement;
    parent.remove();

    attSupEmails();
  };

  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi as RegExp;

  const addLiEmail = () => {
    const liEmail = document.querySelector(".liEmail");
    const listaEmailElement = document.querySelector(
      "#listaEmail"
    ) as HTMLInputElement;

    const check = regexExp.test(listaEmailElement.value);

    if (!liEmail || listaEmailElement.value == "" || !check) {
      console.log(listaEmailElement.value);
      console.log(check);
      console.log(liEmail);

      return;
    }

    const add = document.createElement("div");
    add.className = "setLiEmail";
    listaEmailElement
      ? (add.innerHTML = `<div class="inner"><p class="liEmail">${listaEmailElement.value}</p><small class="minus"></small></div>`)
      : null;
    liEmail?.appendChild(add);

    const allMinus = document.querySelectorAll(
      ".minus"
    ) as NodeListOf<HTMLDivElement>;

    allMinus.forEach((minus) => {
      minus.removeEventListener("click", (e) => removeEmail(e), false);
    });

    allMinus.forEach((minus) => {
      minus.addEventListener("click", (e) => removeEmail(e), false);
    });

    attLiEmails();

    listaEmailElement.value = "";
  };

  const AddSup = () => {
    const supEmail = document.querySelector(".supEmail");
    const suporteEmailElement = document.querySelector(
      "#suporteEmail"
    ) as HTMLInputElement;

    const check = regexExp.test(suporteEmailElement.value);

    if (!supEmail || suporteEmailElement.value == "" || !check) {
      Swal.fire({
        ...swalConfig,
        title: "Erro ao adicionar email",
        icon: "error",
        text: "Email inválido",
      });
      return;
    }

    const add = document.createElement("div");
    add.className = "setSupEmail";
    suporteEmailElement
      ? (add.innerHTML = `<div class="inner"><p class="supEmail">${suporteEmailElement.value}</p><small class="minus"></small></div>`)
      : null;
    supEmail?.appendChild(add);

    const allMinus = document.querySelectorAll(
      ".minus"
    ) as NodeListOf<HTMLDivElement>;

    allMinus.forEach((minus) => {
      minus.removeEventListener("click", (e) => removeEmail(e), false);
    });

    allMinus.forEach((minus) => {
      minus.addEventListener("click", (e) => removeEmail(e), false);
    });

    attSupEmails();

    suporteEmailElement.value = "";
  };

  return (
    <>
      <div className="criarProcesso">
        <h1>Criar Processo</h1>

        <form onSubmit={handleSubmit}>
          <div className="top">
            <section className="left">
              <label>Nome</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  onChange={(e) => setNome(e.target.value)}
                  required
                />
              </div>
              <label>Lista E-mail</label>
              <div className="input-wrapper liEmail">
                <div className="first">
                  <input type="text" name="listaEmail" id="listaEmail" />
                  <div className="add" onClick={addLiEmail}>
                    {PlusLg}
                  </div>
                </div>
              </div>
              <label>Suporte E-mail</label>
              <div className="input-wrapper supEmail">
                <div className="first">
                  <input type="text" name="suporteEmail" id="suporteEmail" />
                  <div className="add" onClick={AddSup}>
                    {PlusLg}
                  </div>
                </div>
              </div>
              <label>Descrição</label>
              <div className="input-wrapper descricao">
                <textarea
                  name="descricao"
                  id="descricao"
                  cols={30}
                  rows={4}
                  maxLength={150}
                  onChange={(e) => setDescricao(e.target.value)}
                  required
                ></textarea>
              </div>
            </section>
            <section className="right">
              <label>Dia</label>
              <select
                name="dia"
                id="dia"
                onChange={(e) => setDia(parseInt(e.target.value))}
                placeholder="Selecione o dia"
                defaultValue={1}
              >
                {opcoes}
              </select>
            </section>
          </div>
          <div className="bottom">
            <button type="submit">Criar</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProcessoCriarPage;
