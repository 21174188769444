import { IObjectResponse, api } from "./Api";

export const CriarUsuario = async (
  nome: string,
  email: string,
  password: string,
  CNPJ: string
) => {
  return await api.post<IObjectResponse>("/user", {
    nome,
    email,
    password,
    CNPJ,
  });
};
