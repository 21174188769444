import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { icons } from "@/assets/icons";

const TopBar = () => {
  const { Bell, CaretDownFill } = icons;

  const { apelido } = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <>
      <nav className="top-bar">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>

        {apelido ? (
          <div className="name">
            <h3>Bem vindo, {apelido}</h3>
          </div>
        ) : null}

        <div className="icons">
          <div className="notificacao">
            <Link to="">{Bell}</Link>
          </div>
          <div className="foto">
            <div className="arrow-down">{CaretDownFill}</div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopBar;
