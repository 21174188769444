import { useEffect, useState } from "react";
import { AuthContext, User } from "./Auth";
import { useNavigate } from "react-router-dom";
import { api, ChecagemError, CreateSession } from "@/clients/Api";

/**
 * Tipando children no props
 */
export interface Props {
  children: JSX.Element;
}

/**
 * Responsavel pela manutenção do contexto de autenticação
 * @returns retornar o contexto já preenchido de autenticação
 */
// eslint-disable-next-line react/prop-types
export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  /**
   * é responsavel pela naveração e troca de telas.
   * @example navigate("/") te direciona a tela home
   */
  const navigate = useNavigate();

  // captura a informação do setState do User
  const [user, setUser] = useState<User | null>();

  // captura informações de loading da aplicação
  const [loading, setLoading] = useState(true);

  // é um hook, responsavel por gerir o entre paginas da aplicação
  useEffect(() => {
    /**
     * informações setadas no localStore
     */
    const recuperacaoUser = localStorage.getItem("user");
    /**
     * Token setado no localStore
     */
    const token = localStorage.getItem("token");

    if (recuperacaoUser && token) {
      setUser(JSON.parse(recuperacaoUser));
      // faz a setagem do token no header da API
      api.token = token;
    }

    setLoading(false);
  }, []);

  /**
   * Function que tentar autenticar usuario, caso consiga seta valores no localStore e headers do axios,
   * redirecionando a tela de home da aplicação.
   * REPASSADO
   */
  const login = async (email: string, password: string): Promise<void> => {
    //api criar uma sessão
    try {
      const response = await CreateSession(email, password);

      const loggerUser = {
        apelido: response.data.apelido,
      };

      const token = response.data.token;

      localStorage.setItem("user", JSON.stringify(loggerUser));
      localStorage.setItem("token", token);
      api.token = token;

      setUser(loggerUser);
      navigate("/");
    } catch (error) {
      const err = ChecagemError(error);

      switch (err.code) {
        case 401:
          alert("login invalido");
          break;

        default:
          alert("Algo errado, olha o log");
          console.log(err.error);
          break;
      }
    }
  };

  /**
   * Faz a limpeza dos dados do usuario quando é solicitado o logout
   */
  const logout = (): void => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    api.token = null;
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        login,
        logout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
