import AppRoutes from "./Routes";
import "./css/root.css";
import "./css/Lista.css";
import "./css/LoginPage.css";
import "./css/Cadastro.css";
import "./css/SideBar.css";
import "./css/TopBar.css";
import "./css/CriarParticipante.css";
import "./css/ProcessoMostrar.css";
import "./css/CriarProcesso.css";
import "./css/Home.css";
import "./css/Tarefas.css";
import "./css/CriarTarefa.css";
import "./css/Redefinir.css";

import "./responsive/Root.css";
import "./responsive/LoginPage.css";
import "./responsive/Cadastro.css";
import "./responsive/SideBar.css";
import "./responsive/TopBar.css";
import "./responsive/Home.css";
import "./responsive/Lista.css";
import "./responsive/ParticipanteCriar.css";
import "./responsive/ProcessoCriar.css";
import "./responsive/Tarefas.css";
import "./responsive/TarefaCriar.css";

/**
 * Contém as informações do APP, é chamado pela index
 */

function App() {
  return (
    <div className="app">
      <AppRoutes />
    </div>
  );
}

export default App;
